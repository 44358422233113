import React, { useEffect } from 'react'

//
import { BlockIntegrations, Layout } from '../components'
import { CUSTOMER_TYPES } from '../components/blocks/customerTypes'
import { MicroCopyContext } from '../components/page-wrapper'
import { ButtonNew, Cta, HeroDual, Loader, Wistia, RichText, Statistic } from '../storybook'
import { BUTTON_VARIANT } from '../storybook/ButtonNew/ButtonNew'
import Socials from '../storybook/Socials/Socials'
import { getWistiaAspectRatio } from '../utils/common'
import { graphqlFetch } from '../utils/graphql-fetch'
import { ImageFluid } from '../utils/image'
import { getMicroCopy } from '../utils/microcopy'
import { buildPageMetaTags } from '../utils/seo'
import { getUrl } from '../utils/urls'

const query = `
  query($contentfulId: String!, $locale: String!) {
    customer(id: $contentfulId, locale: $locale) {
      title
      storyText {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              url
              title
            }
          }
          entries {
            hyperlink {
              __typename
              ...on Page {
                sys {
                  id
                }
                slug
              }
              ...on Integration {
                sys {
                  id
                }
                slug
              }
							...on Customer {
                sys {
                  id
                }
                slug
              }
              ...on Article {
                sys {
                  id
                }
                slug
                categoriesCollection(limit: 1) {
                  items {
                    sys {
                      id
                    }
                    slug
                  }
                }
              }
            }
          }
        }
      }
      integrationsCollection {
        items {
          sys {
            id
          }
          title
          label
          logo {
            url
          }
          slug
          categoriesCollection(limit:10) {
            items {
              sys {
                id
              }
              title
              type
            }
          }
        }
      }
    }
  }
`

const Customer = ({ pageContext }) => {
  const {
    contentfulId,
    description,
    name,
    logo,
    downloadAction,
    benefits,
    customerType,
    image,
    video,
    locale,
    langSlugs,
    allLangSlugs,
    allMarkets,
    marketsSlugs,
    settings,
    metaImage,
    metaTags,
    marketsMetaTags,
    siteMetadata,
    market
  } = pageContext

  const microCopyData = React.useContext(MicroCopyContext)
  const { slugPrefix, footer } = market
  const { integrationDetailGetStartedBlock } = settings

  const pageData = { metaTags, metaImage, pageType: 'customer', marketsMetaTags }

  if (!pageData.metaTags.description) {
    pageData.metaTags.description = getMicroCopy({
      key: 'global.customerMetaDescription',
      data: microCopyData,
      tokens: { customerName: name }
    })
  }

  const pageMetaTags = buildPageMetaTags(pageData, siteMetadata)

  const [isLoading, setIsLoading] = React.useState(true)
  const [templateData, setTemplateData] = React.useState({})

  useEffect(() => {
    graphqlFetch(query, { contentfulId, locale })
      .then((res) => {
        setTemplateData({
          storyText: res?.data?.customer?.storyText,
          integrations: res?.data?.customer?.integrationsCollection?.items
        })
        setIsLoading(false)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  const { integrations, storyText } = templateData

  return (
    <Layout
      metaTags={pageMetaTags}
      marketsSlugs={marketsSlugs}
      allLangSlugs={allLangSlugs}
      allMarkets={allMarkets}
      langSlugs={langSlugs}
      header={market.header}
      footerMainNavigation={footer?.mainNavigation}
      footerSocialMedia={footer?.socialMedia}
      footerLegalNavigation={footer?.legalNavigation}
      footerActions={footer?.actions}
      marketPhoneNo={market.phoneNumber}
      marketPhoneNo2={market.phoneNumber2}
    >
      <HeroDual
        title={name}
        text={description}
        logo={logo}
        eyebrow={
          customerType
            ? getMicroCopy({ key: `global.${CUSTOMER_TYPES[customerType]}`, data: microCopyData })
            : ''
        }
        prefaceBelow={false}
        media={
          video ? (
            <Wistia
              videoSource={video?.videoSource}
              thumbnail={video?.thumbnail || image || null}
              videoId={video?.videoId}
              aspectRatioX={getWistiaAspectRatio(video?.aspectRatio).x}
              aspectRatioY={getWistiaAspectRatio(video?.aspectRatio).y}
              cover={video?.cover}
              autoPlay={video?.autoPlay}
              controlsVisibleOnLoad={video?.controlsVisibleOnLoad}
              muted={video?.muted}
              transparentBackground={video?.transparentBackground}
              popover={video?.popover}
              hideControls={video?.hideControls}
            />
          ) : (
            <ImageFluid image={image} loading={'eager'} />
          )
        }
        classes={'c-customer'}
      >
        {benefits && Array.isArray(benefits) ? (
          <div className="l-grid l-grid--default-spacing">
            {benefits.map((benefit) => {
              return (
                <div key={benefit.id} className="c-customer__statistic">
                  <Statistic number={benefit.number} unit={benefit.unit} title={benefit.title} />
                </div>
              )
            })}
          </div>
        ) : null}
        {downloadAction ? (
          <div>
            <ButtonNew variant={BUTTON_VARIANT.GREEN} url={getUrl(downloadAction, slugPrefix)}>
              {downloadAction.title}
            </ButtonNew>
          </div>
        ) : null}
      </HeroDual>

      {!isLoading ? (
        <>
          {integrations && integrations.length > 0 && (
            <BlockIntegrations
              anchor={'integrations'}
              data={{
                title: getMicroCopy({
                  key: `integration.integrationsBlockTitleWithCustomerName`,
                  data: microCopyData,
                  tokens: { name: name }
                }),
                description: getMicroCopy({
                  key: `integration.integrationsBlockDescription`,
                  data: microCopyData
                }),
                ctaLabel: getMicroCopy({
                  key: `integration.integrationsBlockCtaLabel`,
                  data: microCopyData
                }),
                ctaUrl: `/${slugPrefix}/integrations`.replace('//', '/'),
                integrations: [
                  {
                    data: integrations.map((integration) => {
                      return {
                        ...integration,
                        type: integration?.categoriesCollection?.items
                          .map((cat) => cat.title)
                          .join(', ')
                      }
                    })
                  }
                ],
                slugPrefix
              }}
            />
          )}

          <section className="l-section">
            <div className="l-container:12/12 l-container--default-spacing">
              <div className="l-grid l-grid--default-spacing">
                <div className="l-grid__col:12/12">
                  <RichText
                    content={storyText}
                    assets={storyText?.links?.assets?.block}
                    entriesLinks={storyText?.links?.entries?.hyperlink}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="l-section">
            <div className="l-container:12/12 l-container--default-spacing">
              <div className="l-grid l-grid--default-spacing">
                <div className="l-grid__col:12/12">
                  <Socials
                    title={getMicroCopy({ key: `global.shareTestimonials`, data: microCopyData })}
                    socials={[
                      {
                        id: '0',
                        label: 'Facebook',
                        url:
                          'https://www.facebook.com/sharer/sharer.php?u=' +
                          encodeURIComponent(window.location.href)
                      },
                      {
                        id: '1',
                        label: 'Twitter',
                        url:
                          'http://www.twitter.com/share?url=' +
                          encodeURIComponent(window.location.href)
                      },
                      {
                        id: '2',
                        label: 'Linkedin',
                        url:
                          'https://www.linkedin.com/sharing/share-offsite/?url=' +
                          encodeURIComponent(window.location.href)
                      }
                    ]}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section className="l-section">
          <div className="l-container:12/12 l-container--default-spacing">
            <Loader />
          </div>
        </section>
      )}

      <Cta
        title={integrationDetailGetStartedBlock?.title || ''}
        text={integrationDetailGetStartedBlock?.description || ''}
        anchor={integrationDetailGetStartedBlock?.anchor}
      >
        {integrationDetailGetStartedBlock?.action && (
          <ButtonNew url={getUrl(integrationDetailGetStartedBlock.action, slugPrefix)}>
            {integrationDetailGetStartedBlock.action.title || ''}
          </ButtonNew>
        )}
      </Cta>
    </Layout>
  )
}

export default Customer
